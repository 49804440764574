import React from 'react'
import CV from '../assets/CV.pdf'

const CallToAction = () => {
  return (
    <div className="CallToAction">
        <a href={CV} download className='btn'>Download Resume</a>
    </div>
  )
}

export default CallToAction