import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Contact from "./components/Contact"
import Header from "./components/Header"
import Navbar from "./components/Navbar"
import Blogs from "./components/Blogs"
import Projects from "./components/Projects"
import Footer from "./components/Footer"
import './App.css';

function App() {
  const [data, setData] = useState([{}])

/*
  useEffect(()=> {
    fetch('/members').then(
      res => res.json()
    ).then
    (data => {
      setData(data)
      console.log(data)
    }
    )
  }, []);
*/
  return( 
    <>
      <Header />
      <Navbar/>
      <Projects /> 
      <Blogs />
      <Contact />
      <Footer />
    </>
  );
}
export default App;
