import React from 'react'
import './Footer.css'
import {IoLogoLinkedin} from 'react-icons/io'
import {IoLogoGithub} from 'react-icons/io'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>David McGuire</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#Projects">Projects</a></li>
        <li><a href="#Blogs">Blogs</a></li>
        <li><a href="#Contact">Contact</a></li>
      </ul>
    
    <div className='footer_socials'>
      <a href="https://www.linkedin.com/in/david-mcguire-190a7121a/"><IoLogoLinkedin icon={IoLogoLinkedin} size = '50'/></a>
      <a href="https://github.com/davidgmcguire"><IoLogoGithub icon={IoLogoGithub} size = '50'/></a>
    </div>

    <div className="footer__copyright">
      <small>&copy; David McGuire. All rights reserved. </small>
    </div>

    </footer>
  )
}

export default Footer