import React from 'react'
import './Navbar.css'
import {FaHome} from 'react-icons/fa'
import {IoIosContact} from 'react-icons/io'
import {BsFillBookFill} from 'react-icons/bs'
import {BsCode} from 'react-icons/bs'
import {useState} from 'react'

const Navbar = () => {
    const [activeNavbar, setActiveNavbar] = useState('#')
  return (
    <nav>
        <a href="#" onClick={() => setActiveNavbar('#')} className={activeNavbar === '#' ? 'active': ''}><FaHome/></a>
        <a href="#Projects" onClick={() => setActiveNavbar('#Projects')} className={activeNavbar === '#Projects' ? 'active': ''}><BsCode/></a>
        <a href="#Blogs" onClick={() => setActiveNavbar('#Blogs')} className={activeNavbar === '#Blogs' ? 'active': ''}><BsFillBookFill/></a>
        <a href="#Contact" onClick={() => setActiveNavbar('#Contact')} className={activeNavbar === '#Contact' ? 'active': ''}><IoIosContact/></a>
    </nav>
  )
}

export default Navbar