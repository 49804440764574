import React from 'react'
import {Helmet} from 'react-helmet'

const Blogs = () => {
  return (
    <section id='Blogs'>
      <h1> Blogs </h1>
        <div class='sk-ww-medium-publication-feed' data-embed-id='105240'> 
          <Helmet>
            <script src='https://widgets.sociablekit.com/medium-publication-feed/widget.js' async defer></script>   
          </Helmet>
        </div>  
    </section>
  )
}



export default Blogs