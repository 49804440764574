import React from 'react'
import {MdConstruction} from 'react-icons/md'

const Projects = () => {
  return (
    <section id='Projects'>
       <h1> Projects </h1>
       <h5> Under Construction</h5>
       <h5> Come back soon!</h5> 
      <MdConstruction icon={MdConstruction} size = '500'/>
    </section>
     
  
    )
}

export default Projects