import React from 'react'
import CV from '../assets/CV.pdf'
import {MdEmail} from 'react-icons/md'

const CallToAction2 = () => {
  return (
    <div className="CallToAction2">
        <a href="mailto:david.mcguire.job@gmail.com" className='btn'>
          <h2>Email Me</h2>
          <MdEmail icon={MdEmail} size = '50'/>
          <h5>david.mcguire.job@gmail.com</h5>
        </a>
    </div>
  )
}

export default CallToAction2