import React from 'react'
import './Contact.css'
import CallToAction2 from './CallToAction2'
import { useRef } from 'react';
import emailjs from 'emailjs-com';


const Contact = () => {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_i6zuotr', 'template_0szzwl9', form.current, 't-kVev7JYa0jWGuIZ')
    
    e.target.reset()
  };
  return (
    <section id='Contact'>
      <h1>Contact Me</h1>
      <h5>Get in Touch</h5>
        <div className='container contact_container'>
          <div className='contact_options'>
            <CallToAction2/>
          </div>
          {}
          <form ref={form} onSubmit={sendEmail}>
            <input type='text' name = 'name' placeholder= 'Your Full Name' required/>
            <input type='email' name = 'email' placeholder= 'Your Email' required/>
            <textarea message='text' rows = '7' placeholder= 'Your Message' required ></textarea>
            <button type='submit' className='btn'>Send Message</button>
          </form>

        </div>
    </section>
  )
}

export default Contact