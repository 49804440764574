import React from 'react'
import CallToAction from './CallToAction'
import David from '../assets/Portfolio_David.JPG'
import HeaderSocialMedia from './HeaderSocialMedia'
import './Header.css';


const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5> Hello I'm</h5>
        <h1> David McGuire</h1>
        <h5 className= "light-text">Systems Design Engineering Student</h5>
        <h5> University of Waterloo</h5>
        <CallToAction />
        <HeaderSocialMedia />

        <div className='David'>
          <img src={David} alt="" />
        </div>
      </div>
    </header>
  )
}

export default Header