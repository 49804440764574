import React from 'react'
import {IoLogoLinkedin} from 'react-icons/io'
import {IoLogoGithub} from 'react-icons/io'

const HeaderSocialMedia = () => {
  return (
    <div className='Header__social__media'>
        <a href="https://www.linkedin.com/in/david-mcguire-190a7121a/" target="_blank" ><IoLogoLinkedin icon={IoLogoLinkedin} size = '100'/></a>
        <a href="https://github.com/davidgmcguire" target="_blank"><IoLogoGithub icon={IoLogoGithub} size = '100'/> </a>
    </div>
  )
}

export default HeaderSocialMedia